import { useApplicationContext } from '@/context/ApplicationContext';
import { WorkOrderStatus } from '@/graphql/types';

import { useCallback } from 'react';
import useTranslation from '../../../utils/i18n/useTranslation';
import { WorkOrderStatusAppearanceInfo } from '../types/workOrderStatusLabel';
import { getWorkOrderStatus } from '../utils/getWorkOrderStatus';

type ReturnType = {
  getStatusLabel: (status: WorkOrderStatus) => string;
  getStatusAppearanceInfo(value: WorkOrderStatus): WorkOrderStatusAppearanceInfo;
};

export const useWorkOrderStatus = (): ReturnType => {
  const { companySetting } = useApplicationContext();

  const { t } = useTranslation();

  const getStatusLabel = useCallback(
    (value: WorkOrderStatus) => {
      if (companySetting) {
        // companySettingの中で、mapで持つようにしても良いかもしれない
        const { customWorkOrderStatuses } = companySetting;
        const customStatus = customWorkOrderStatuses.find((entry) => entry.status === value);
        if (customStatus) return customStatus.label;
      }

      const { defaultLabelKey, label } = getWorkOrderStatus(value);
      return t(`status.${defaultLabelKey}`, label);
    },
    [companySetting, t]
  );

  const getStatusAppearanceInfo = useCallback(
    (value: WorkOrderStatus) => {
      const status = getWorkOrderStatus(value);
      const label = getStatusLabel(value);

      return { ...status, label };
    },
    [getStatusLabel]
  );

  return {
    getStatusLabel,
    getStatusAppearanceInfo,
  };
};
