import { WorkOrderStatus } from '@/graphql/types';
import theme from '@/plugin/chakra';
import { assertNever } from '@/utils/types/types';
import { MdCheckCircle, MdDoNotDisturbOn, MdPauseCircle, MdPlayCircle } from 'react-icons/md';
import { WorkOrderStatusAppearanceInfo } from '../types/workOrderStatusLabel';

const openStatus: WorkOrderStatusAppearanceInfo = {
  value: 'open',
  label: '未着手',
  color: 'blue.500',
  chakraColor: theme.colors.blue[500],
  defaultLabelKey: 'open',
  icon: MdDoNotDisturbOn,
};

const onHoldStatus: WorkOrderStatusAppearanceInfo = {
  value: 'onHold',
  label: '保留中',
  color: 'warning.500',
  chakraColor: theme.colors.warning[500],
  defaultLabelKey: 'on-hold',
  icon: MdPauseCircle,
};

const inProgressStatus: WorkOrderStatusAppearanceInfo = {
  value: 'inProgress',
  label: '作業中',
  color: 'warning.500',
  chakraColor: theme.colors.warning[500],
  defaultLabelKey: 'in-progress',
  icon: MdPlayCircle,
};

const doneStatus: WorkOrderStatusAppearanceInfo = {
  value: 'done',
  label: '完了',
  color: 'neutral.500',
  chakraColor: theme.colors.neutral[500],
  defaultLabelKey: 'done',
  icon: MdCheckCircle,
};

export const getWorkOrderStatus = (value: WorkOrderStatus) => {
  switch (value) {
    case WorkOrderStatus.Open:
      return openStatus;
    case WorkOrderStatus.OnHold:
      return onHoldStatus;
    case WorkOrderStatus.InProgress:
      return inProgressStatus;
    case WorkOrderStatus.Done:
      return doneStatus;
    default:
      assertNever(value);
  }
};
